import {useContext, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {useNavigate} from 'react-router';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {ConnectWalletButton} from 'src/components/ConnectWalletButton';
import {PoolItem} from 'src/components/Holdings';
import {BodyVariant} from 'src/components/Typography';
import {paths} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

export const Liquidity = () => {
  const {liquidityPools} = useContext(AppContext);
  const {account} = useWeb3React();
  const navigate = useNavigate();
  const modalContext = useModals();
  const {check} = useAgreementCheck();

  const [liquidityPoolsLength, setLiquidityPoolsLength] = useState(0);

  const filteredLiquidityPools = useMemo(() => {
    const _liquidityPools = liquidityPools?.filter(
      (item) =>
        item.balance &&
        getTokenUSDPrice(formatBigNumber(item.balance, 18), item.lpTokenPriceUSD) > 0.1 &&
        item?.platform?.name === 'Liquidus',
    );

    setLiquidityPoolsLength(_liquidityPools.length);

    return _liquidityPools;
  }, [liquidityPools]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const onImportPool = () => {
    const payload = {isOpen: true};
    check(() => modalContext.dispatch({type: 'updateImportPoolModal', payload}));
  };

  return (
    <Wrapper>
      <Container>
        {account ? (
          <>
            <StyledSection>
              <StyledFullRow>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
                  Your Liquidity
                </BodyVariant>
              </StyledFullRow>
              <Description fontType={liquidityPoolsLength > 0 ? PARAGRAPH_FONT_ENUM.SMALL : PARAGRAPH_FONT_ENUM.BIG}>
                {liquidityPoolsLength > 0 ? (
                  <>
                    All your Liquidus liquidity pools. Go{' '}
                    <HoldingsLink onClick={() => handleNavigation(paths.holdings)}>Holdings</HoldingsLink> to view all
                    platforms.
                  </>
                ) : (
                  "You don't have any liquidity on the Liquidus platform yet."
                )}
              </Description>
              {liquidityPoolsLength > 0 &&
                filteredLiquidityPools.map((item, index) => (
                  <PoolItem key={index} data={item} isPlatformLiquidity={true} />
                ))}
            </StyledSection>
            <StyledSection>
              <StyledButton
                width='100%'
                color={COLORS.PRIMARY}
                size={BUTTON_SIZE_ENUM.DEFAULT}
                title='+ Add Liquidity'
                onClick={() => handleNavigation(paths.addLiquidity)}
              />
            </StyledSection>
            <StyledSection>
              <StyledFullRow>
                <BodyVariant
                  color={COLORS.PRIMARY}
                  size={BODY_FONT_ENUM.MEDIUM_MOBILE}
                  mobile={BODY_FONT_ENUM.LARGE_MOBILE}
                >
                  Don&apos;t see a pair you joined?
                </BodyVariant>
              </StyledFullRow>
              <StyledButton
                width='fit-content'
                background
                borderColor={COLORS.SECONDARY}
                color={COLORS.PRIMARY}
                size={BUTTON_SIZE_ENUM.SMALL}
                title='Find other LP tokens or pools'
                onClick={onImportPool}
              />
            </StyledSection>
          </>
        ) : (
          <ConnectWalletButton />
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
`;

const Container = styled.div`
  width: 712px;
  min-height: calc(100vh - 81px);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));
  padding: 80px 0;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 24px 16px;
  }
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Description = styled.p<{fontType: PARAGRAPH_FONT_ENUM}>`
  font-size: ${(props) => props.fontType};
  text-align: left;
  color: ${COLORS.PRIMARY};
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)<{width: string; background?: boolean}>`
  background: ${(props) => props.background && 'transparent'};
  border-radius: 6px;
  margin-top: 10px;
  width: ${(props) => props.width};
`;

const HoldingsLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
