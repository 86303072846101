import {BigNumber} from 'ethers';

export enum LiquidityTokenField {
  TOKENA = 'TOKEN_A',
  TOKENB = 'TOKEN_B',
}

export type importLiquidityType = {
  token0Address?: string;
  token1Address?: string;
  liquidityData?: {
    address?: {hash?: string};
    balance?: BigNumber;
    decimals?: number;
    reserve0?: BigNumber;
    reserve1?: BigNumber;
    totalSupply?: BigNumber;
  };
};
