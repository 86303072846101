import React, {useEffect, useRef} from 'react';

import liqLogo from 'src/assets/images/tokens/svg/NewLIQ.svg';
import {DEVICE_ENUM} from 'src/styles';
import {Conversation} from 'src/types';
import styled, {keyframes} from 'styled-components';

import {Spinner} from '../Spinner';

const MessageHistory: React.FC<Conversation> = ({messages}) => {
  const historyContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (historyContainerRef.current) {
      historyContainerRef.current.scrollTop = historyContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <HistoryContainer ref={historyContainerRef} className='MessageBox'>
      {messages.length === 0 && <Placeholder>Ask a question</Placeholder>}
      {messages.map((message, index) =>
        message.question || message.answer ? (
          <React.Fragment key={index}>
            <UserMessage>{message.question}</UserMessage>
            <BotMessageContainer centerPos={!message.answer}>
              <BotAvatar src={liqLogo} alt='Chatbot Avatar' />
              {message?.answer ? <BotMessage>{message.answer}</BotMessage> : <Spinner key={index} />}
            </BotMessageContainer>
          </React.Fragment>
        ) : null,
      )}
    </HistoryContainer>
  );
};

export default MessageHistory;

const HistoryContainer = styled.div`
  overflow-y: auto;
  margin: 25px 0 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  justify-content: flex-start;
  background-color: #f3f2f5;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Placeholder = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #c0c0c0;
  text-align: center;
  margin: 10px 0;
`;

const UserMessage = styled.div`
  margin: 5px 0;
  padding: 15px;
  border-radius: 19.75px 0px 19.75px 19.75px;
  background-color: #112455;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  color: white;
  max-width: 60%;
  align-self: flex-end;
  display: inline-block;
`;

const BotMessageContainer = styled.div<{centerPos?: boolean}>`
  display: flex;
  align-items: ${(props) => (props.centerPos ? 'center' : 'flex-end')};
  margin: 5px 0;
`;

const scaleLeft = keyframes`
  0% {
    transform: scale(0.5);
    transform-origin: 0% 100%;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 100%;
  }
`;

const BotMessage = styled.div`
  padding: 30px 10px 11.44px 10px;
  border-radius: 19.75px 19.75px 19.75px 0px;
  background-color: #f8f8fd;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: black;
  text-align: left;
  max-width: 60%;
  display: inline-block;
  animation: ${scaleLeft} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    max-width: 100%;
  }
`;

const BotAvatar = styled.img`
  width: 40px;
  height: 40px;
  margin: 0px 8px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 30px;
    height: 30px;
    margin: 0;
  }
`;
