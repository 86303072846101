import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {load, save} from 'redux-localstorage-simple';

import {farmSlice} from './farm/reducer';
import {liquiditySlice} from './liquidity/reducer';
import {swapSlice} from './swap/reducer';
import {tokenMigrationSlice} from './tokenMigration/reducer';
import {transactionSlice} from './transactions/reducer';
import {userSlice} from './user/reducer';
import {walletSlice} from './wallets/reducer';

const rootReducer = combineReducers({
  [farmSlice.name]: farmSlice.reducer,
  [swapSlice.name]: swapSlice.reducer,
  [transactionSlice.name]: transactionSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [walletSlice.name]: walletSlice.reducer,
  [tokenMigrationSlice.name]: tokenMigrationSlice.reducer,
  [liquiditySlice.name]: liquiditySlice.reducer,
});

const PERSISTED_KEYS: string[] = ['user', 'wallets', 'transactions'];

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({thunk: false, immutableCheck: false, serializableCheck: false}).concat(
      save({states: PERSISTED_KEYS, debounce: 100}),
    ),
  preloadedState: load({states: PERSISTED_KEYS}),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
