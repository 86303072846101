import React from 'react';

import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type AvailablePairProps = {
  token0: IWhitelistToken;
  token1?: IWhitelistToken;
  formattedReserve0?: string;
  formattedReserve1?: string;
  account?: string;
  onConnectWallet?: () => void;
  onManage?: () => void;
};

export const AvailablePair = ({
  token0,
  token1,
  formattedReserve0,
  formattedReserve1,
  account,
  onConnectWallet,
  onManage,
}: AvailablePairProps) => {
  const reserves =
    formattedReserve0 && formattedReserve1
      ? `${formattedReserve0} ${token0?.symbol} / ${formattedReserve1} ${token1?.symbol}`
      : 'N/A';
  return (
    <Body>
      <TokenInfoBox>
        <TokenContainer>
          <TokenBox>
            <TokenIcon width={36} height={36} token={token0} />
            <SecondTokenIcon width={36} height={36} token={token1} />
          </TokenBox>
          <StyledCol>
            <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.MEDIUM} mobile={BODY_FONT_ENUM.SMALL_MOBILE}>
              {token0?.symbol}-{token1?.symbol} LP
            </BodyVariant>
            <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.XSMALL}>
              {reserves}
            </BodyParagraph>
          </StyledCol>
        </TokenContainer>
      </TokenInfoBox>
      <ButtonWrapper>
        {account ? (
          <StyledButton
            width='100%'
            radius
            borderColor={COLORS.SECONDARY}
            color={COLORS.PRIMARY}
            size={BUTTON_SIZE_ENUM.DEFAULT}
            title='Manage'
            onClick={onManage}
          />
        ) : (
          <Button color={COLORS.PRIMARY} title='Connect Wallet' onClick={onConnectWallet} />
        )}
      </ButtonWrapper>
    </Body>
  );
};

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  padding: 16px 25px;
  border-radius: 10px;
`;

const TokenInfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
`;

const StyledCol = styled.div<{gap?: number; marginTop?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const Body = styled.div`
  padding: 16px 24px;
`;

const StyledButton = styled(Button)<{width: string; background?: boolean; radius?: boolean}>`
  background: ${(props) => props.background && 'transparent'};
  border-radius: ${(props) => props.radius && '6px'};
  margin-top: 10px;
  width: ${(props) => props.width};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;
