import React, {useEffect, useRef, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import ToggleIcon from 'src/assets/images/close.png';
import ChatInput from 'src/components/ChatBot/chatBotInputText';
import MessageHistory from 'src/components/ChatBot/messageHistory';
import PredefinedQuestions from 'src/components/ChatBot/preDefinedQuestions';
import PreviousChats from 'src/components/ChatBot/prevConversations';
import {CloseIcon} from 'src/components/Svgs';
import {useModals} from 'src/contexts/modals';
import {useChatbot} from 'src/hooks/Chatbot/useChatBot';
import './chatStyles.css';
import {COLORS} from 'src/styles';
import {DEVICE_ENUM} from 'src/styles/size';
import styled, {keyframes} from 'styled-components';

const predefinedQuestions = [
  'What is Liquidus?',
  'How can I top-up a farm?',
  'How to receive tokens?',
  'What Networks are supported?',
  'What is Yield Scanner?',
  'How can i earn 100% APY?',
];

const ChatBotModal: React.FC<{isOpen: boolean; onClose: () => void}> = ({isOpen, onClose}) => {
  const {
    sendQuery,
    currentMessages,
    hasError,
    loadConversation,
    conversations,
    clearCurrentMessages,
    removePreviousConversation,
  } = useChatbot();
  const {account} = useWeb3React();
  const {dispatch} = useModals();

  const popupRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [toggleAnimation, setToggleAnimation] = useState(true);

  const handleClose = () => {
    setToggleAnimation(false);
    setTimeout(() => {
      onClose();
      clearCurrentMessages();
      setToggleAnimation(true);
      setIsExpanded(true);
    }, 500);
  };

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.removeProperty('overflow');
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event: {target: unknown}) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  if (!isOpen) return null;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const onConnectWallet = () => {
    const payload = {isOpen: true};
    dispatch({type: 'updateWalletConnectModal', payload});
  };

  const handleSend = (message: string) => {
    if (!account) {
      handleClose();
      onConnectWallet();
      return;
    }

    if (isExpanded) toggleExpand();

    if (hasError) throw new Error('Error sending message\nPlease try again');

    try {
      sendQuery(message);
    } catch (error) {
      console.error('Failed to send query:', error);
    }
  };

  const handleSelectQuestion = (question: string) => {
    handleSend(question);
  };

  const handleSelectChat = (index: number) => {
    loadConversation(index);
    if (isExpanded) toggleExpand();
  };

  return (
    <ModalContainer ref={popupRef} animation={toggleAnimation}>
      <ModalContent>
        <CloseButton onClick={handleClose}>
          <CloseIcon color={COLORS.PRIMARY} />
        </CloseButton>
        <MessageHistory messages={currentMessages} />
        <QuestionsContainer open={isExpanded} className='animated-height'>
          <ExpandButton onClick={toggleExpand}>
            <Toggle src={ToggleIcon} />
          </ExpandButton>
          <QuestionsContentContainer>
            <PredefinedQuestions questions={predefinedQuestions} onSelect={handleSelectQuestion} />
            {conversations?.length > 0 && (
              <PreviousChats
                conversations={conversations}
                onSelect={handleSelectChat}
                onDelete={removePreviousConversation}
              />
            )}
          </QuestionsContentContainer>
        </QuestionsContainer>
        <ChatInput onSend={handleSend} />
        {hasError && <div>An error occurred from the Chatbot.</div>}
      </ModalContent>
    </ModalContainer>
  );
};

export default ChatBotModal;

const scaleRight = keyframes`
  0% {
    transform: scale(0.5);
    transform-origin: 100% 100%;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 100%;
  }
`;

const scaleRightOut = keyframes` scale-out-br 
  0% {
    transform: scale(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scale(0);
    transform-origin: 100% 100%;
    opacity: 0.2;
  }
`;

const ModalContainer = styled.div<{animation?: boolean}>`
  position: fixed;
  bottom: 15px;
  right: 20px;
  width: 60vh;
  height: 80vh;
  background-color: transparent;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  z-index: 1000;
  animation: ${(props) => (props?.animation ? scaleRight : scaleRightOut)} 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 80%;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: #f3f2f5;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  padding: 0px;
  width: 50px;
  height: 30px;
  align-self: flex-end;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 30px;
    right: 3px;
    top: 3px;
  }
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background: #e5e8ef;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const QuestionsContainer = styled.div<{open?: boolean}>`
  display: flex;
  flex-direction: column;
`;

const QuestionsContentContainer = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: solid #e5e8ef 5px;
  border-right: solid #e5e8ef 5px;
  margin: 0 0 -10px;
  padding: 15px;
`;

const Toggle = styled.img<{open?: boolean}>`
  width: 16px;
  height: 16px;
`;
