import {useCallback, useContext, useEffect, useState} from 'react';

import {TransactionResponse} from '@ethersproject/providers';
import {Currency, CurrencyAmount} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {failHandleEnum} from 'src/constants/migrator';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {useLiquidityPool} from 'src/hooks/useLiquidityPool';
import {COLORS} from 'src/styles';
import {IItemType, IWhitelistToken} from 'src/types';
import styled from 'styled-components';

import {AddLiquidity} from './AddLiquidity';
import {ApproveToken0} from './ApproveToken0';
import {ApproveToken1} from './ApproveToken1';
import {AddLiquiditySuccessful} from './SuccessfulAddLiquidity';

export type AddLiquidityModalProps = {
  isOpen: boolean;
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  tokenAddressA: string;
  tokenAddressB: string;
  tokenRates: string[];
  slippage: IItemType;
  liquidityAmout: number;
  token0Amount: BigNumber;
  token1Amount: BigNumber;
  token0CurrencyAmount: CurrencyAmount<Currency>;
  token1CurrencyAmount: CurrencyAmount<Currency>;
  inputValue: number;
  token0MinAmount: BigNumber;
  token1MinAmount: BigNumber;
  deadline: number;
  nativeTokenInvolved: IWhitelistToken;
  shareOfPool: number;
  shareOfPoolDecimals: number;
  onDismiss?: () => void;
};

export const AddLiquidityModal = ({
  isOpen = false,
  token0,
  token1,
  tokenRates,
  slippage,
  liquidityAmout,
  shareOfPool,
  shareOfPoolDecimals,
  token0Amount,
  token1Amount,
  tokenAddressA,
  tokenAddressB,
  token0CurrencyAmount,
  token1CurrencyAmount,
  inputValue,
  token0MinAmount,
  token1MinAmount,
  deadline,
  nativeTokenInvolved,
  onDismiss,
}: AddLiquidityModalProps) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Add Liquidity');
  const [failHandle, setFailHandle] = useState<failHandleEnum>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const {refreshFarmsAndLPs, refreshTokens} = useContext(AppContext);

  const {account} = useWeb3React();

  const {addLiquidityTx, isToken0Approved, isToken1Approved, approveToken0, approveToken1} = useLiquidityPool({
    token0,
    token1,
    token0Address: token0?.address ?? tokenAddressA,
    token1Address: token1?.address ?? tokenAddressB,
    token0Amount,
    token1Amount,
    token0MinAmount,
    token1MinAmount,
    userAddress: account,
    deadline,
    isNative: nativeTokenInvolved ? true : false,
    token0CurrencyAmount,
    token1CurrencyAmount,
  });

  useEffect(() => {
    if (isToken0Approved && isToken1Approved) setStep(2);
    else if (!isToken0Approved && !isToken1Approved) setStep(0);
    else if (!isToken0Approved) setStep(0);
    else if (!isToken1Approved) setStep(1);
  }, [isToken0Approved, isToken1Approved, token0, token1, isOpen]);

  const approveTokenCallback = async (state: boolean, callbackFn: () => Promise<TransactionResponse>, step: number) => {
    setLoading(true);
    setErrorMsg(undefined);
    if (!state) {
      try {
        const approveTx = await callbackFn();
        await approveTx.wait();
        setStep(step);
      } catch (e) {
        //@ts-expect-error: error object contains reason and message
        if (e.reason !== zapKnownErrorReasons.USERREJECTION) {
          console.log({e});
          //@ts-expect-error: error object contains reason and message
          setErrorMsg(e.reason);
          step === 1 ? setFailHandle(failHandleEnum.APPROVE0) : setFailHandle(failHandleEnum.APPROVE1);
        }
        setLoading(false);
        return;
      }
    } else if (state) {
      setStep(step);
    }
    setLoading(false);
  };

  const onApproveToken0 = useCallback(async () => {
    await approveTokenCallback(isToken0Approved, approveToken0, 1);
  }, [approveToken0, isToken0Approved]);

  const onApproveToken1 = useCallback(async () => {
    await approveTokenCallback(isToken1Approved, approveToken1, 2);
  }, [approveToken1, isToken1Approved]);

  // add liquidity
  const OnAddLiquidity = useCallback(async () => {
    setLoading(true);
    setErrorMsg(undefined);
    try {
      const addLiquidity = await addLiquidityTx();
      await addLiquidity.wait();
      setTitle('Add Liquidity Successful');
      setStep(3);
    } catch (e) {
      //@ts-expect-error: error object contains reason and message
      if (e.reason !== zapKnownErrorReasons.USERREJECTION) {
        console.log({e});
        //@ts-expect-error: error object contains reason and message
        setErrorMsg(e.reason ?? e.message);
        setFailHandle(failHandleEnum.ADDLIQUIDITY);
      }
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [addLiquidityTx]);

  const onHandleDismiss = () => {
    setStep(0);
    setErrorMsg(undefined);
    return onDismiss();
  };

  const onFail = async () => {
    switch (failHandle) {
      case failHandleEnum.APPROVE0:
        return onApproveToken0();
      case failHandleEnum.APPROVE1:
        return onApproveToken1();
      case failHandleEnum.ADDLIQUIDITY:
        return OnAddLiquidity();
      default:
        return onHandleDismiss();
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={onHandleDismiss}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <ApproveToken0
            token={token0}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveToken0}
            errorMsg={errorMsg}
            onFail={onFail}
          />
        )}
        {step === 1 && (
          <ApproveToken1
            token={token1}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveToken1}
            errorMsg={errorMsg}
            onFail={onFail}
          />
        )}
        {step === 2 && (
          <AddLiquidity
            token0={token0}
            token1={token1}
            liquidity={liquidityAmout}
            share={shareOfPool}
            shareDecimals={shareOfPoolDecimals}
            token0Amount={token0Amount}
            token1Amount={token1Amount}
            rate={tokenRates}
            slippage={slippage?.value}
            totalInputValue={inputValue}
            loading={loading}
            disabled={loading}
            onConfirm={OnAddLiquidity}
            errorMsg={errorMsg}
            onFail={onFail}
          />
        )}
        {step === 3 && (
          <AddLiquiditySuccessful
            token0={token0}
            token1={token1}
            liquidity={liquidityAmout}
            share={shareOfPool}
            shareDecimals={shareOfPoolDecimals}
            token0Amount={token0Amount}
            token1Amount={token1Amount}
            totalInputValue={inputValue}
            loading={loading}
            disabled={loading}
            onConfirm={() => {
              onHandleDismiss();
              refreshTokens(true);
              refreshFarmsAndLPs();
            }}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
