import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {useNavigate} from 'react-router';
import {CloseIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {getChainInfo, paths} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useLiquidityPool} from 'src/hooks/useLiquidityPool';
import {useToken} from 'src/hooks/useToken';
import {useAppDispatch} from 'src/state/hooks';
import {liquiditySlice} from 'src/state/liquidity/reducer';
import {swapSlice} from 'src/state/swap/reducer';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {Field, IWhitelistToken, POOL_TYPE} from 'src/types';
import {importLiquidityType} from 'src/types/liquidity';
import {formatBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import {AvailablePair} from './AvailablePair';
import {ExistingPoolCheck} from './ExistingPoolCheck';
import {NoPair} from './NoPair';
import {Modal} from '../Modal';

type ImportPoolModalProps = {
  isOpen: boolean;
};

export const ImportPoolModal = ({isOpen}: ImportPoolModalProps) => {
  const {liquidityPools} = useContext(AppContext);
  const {account, chainId} = useWeb3React();
  const chain = getChainInfo(chainId);
  const modalContext = useModals();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const {getTokenByAddress, getTokenBySymbol} = useToken();

  const initialToken0 = getTokenBySymbol(chain?.symbol);

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tokenAddress0, setTokenAddress0] = useState<string | undefined>(undefined);
  const [tokenAddress1, setTokenAddress1] = useState<string | undefined>(undefined);
  const [pairInfo, setPairInfo] = useState<importLiquidityType | undefined>(undefined);

  useEffect(() => {
    setTokenAddress0(initialToken0?.address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const title = step === 2 ? 'Pool Found!' : 'Import Pool';
  const subHeader = step === 2 ? 'Manage your pool' : 'Import an existing pool';

  const onDismiss = () => {
    setStep(0);
    setTokenAddress0(chain?.symbol);
    setTokenAddress1(undefined);
    setPairInfo(undefined);
    setLoading(false);
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateImportPoolModal', payload});
  };

  const token0 = useMemo(() => getTokenByAddress(tokenAddress0), [getTokenByAddress, tokenAddress0]);
  const token1 = useMemo(
    () => (tokenAddress1 ? getTokenByAddress(tokenAddress1) : undefined),
    [getTokenByAddress, tokenAddress1],
  );

  const {createPair, getPairData} = useLiquidityPool({
    token0,
    token1,
  });

  const [formattedReserve0, formattedReserve1] = useMemo(() => {
    const getFormattedReserve = (reserve: BigNumber, decimal: number) => formatBigNumber(reserve, decimal);

    const reserve0 = getFormattedReserve(pairInfo?.liquidityData?.reserve0, pairInfo?.liquidityData?.decimals);
    const reserve1 = getFormattedReserve(pairInfo?.liquidityData?.reserve1, pairInfo?.liquidityData?.decimals);

    const output0 = token0?.customToken ? reserve0?.toExponential(2) : reserve0?.toFixed(7);
    const output1 = token1?.customToken ? reserve1?.toExponential(2) : reserve1?.toFixed(7);

    return [output0, output1];
  }, [pairInfo, token0, token1]);

  const setTokens = () => {
    appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token0.globalName}));
    appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: token1.globalName}));
    localStorage?.setItem('prevURL', location.pathname);
  };

  const data = useMemo(() => {
    return liquidityPools.find((item) => item.address.hash === pairInfo?.liquidityData?.address?.hash);
  }, [liquidityPools, pairInfo]);

  const onManage = () => {
    !data && appDispatch(liquiditySlice.actions.setImportLiquidityData({importContent: pairInfo}));
    const availablePath = `${paths.poolDetail}?type=${POOL_TYPE.LIQUIDITY_POOL}&address=${
      pairInfo?.liquidityData?.address?.hash
    }&platform=Liquidus&import=${!data}`;
    onDismiss();
    navigate(availablePath);
  };

  const handleToken0Select = useCallback((token: IWhitelistToken) => {
    setTokenAddress0(token?.address);
  }, []);

  const handleToken1Select = useCallback((token: IWhitelistToken) => {
    setTokenAddress1(token?.address);
  }, []);

  const onConnectWallet = () => {
    const payload = {isOpen: true, next: 'updateTokenReceiveModal'};
    modalContext.dispatch({type: 'updateWalletConnectModal', payload});
  };

  useEffect(() => {
    if (token0 && token1)
      getPairData().then(async (res) => {
        if (res.availPair) {
          setPairInfo({
            liquidityData: {
              balance: res?.balance,
              reserve0: res?.reserveA,
              reserve1: res?.reserveB,
              decimals: res?.decimals,
              address: {hash: res?.pair},
              totalSupply: res?.ts,
            },
            token0Address: res?.tokens?.token0Address,
            token1Address: res?.tokens?.token1Address,
          });
          setTokenAddress0(res.tokens.token0Address);
          setTokenAddress1(res.tokens.token1Address);
          setStep(2);
        } else {
          setPairInfo(undefined);
          setStep(1);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress0, tokenAddress1]);

  const onCreateNewPair = async () => {
    setLoading(true);
    const newPair = await createPair();
    if (newPair) {
      setTokens();
      onDismiss();
      navigate(paths.addLiquidity);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>{title}</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.XSMALL}>
            {subHeader}
          </BodyParagraph>
        </Header>
        {step === 0 && (
          <ExistingPoolCheck
            token0={token0}
            token1={token1}
            onToken0Select={handleToken0Select}
            onToken1Select={handleToken1Select}
          />
        )}
        {step === 1 && (
          <NoPair
            token0={token0}
            token1={token1}
            account={account}
            onConnectWallet={onConnectWallet}
            onToken0Select={handleToken0Select}
            onToken1Select={handleToken1Select}
            onCreatePair={onCreateNewPair}
            loading={loading}
          />
        )}
        {step === 2 && (
          <AvailablePair
            token0={token0}
            token1={token1}
            account={account}
            onConnectWallet={onConnectWallet}
            formattedReserve0={formattedReserve0}
            formattedReserve1={formattedReserve1}
            onManage={onManage}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
