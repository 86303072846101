export const paths = {
  root: '/',
  articleView: '/article-view',
  home: '/home',
  swap: '/swap',
  holdings: '/holdings',
  farm: '/farm',
  wallet: '/wallet',
  marketOverview: '/market-overview',
  bridge: '/bridge',
  send: '/send',
  receive: '/receive',
  history: '/history',
  poolDetail: '/pool-detail',
  premiumDetail: '/premium-detail',
  yieldScanner: '/yield-scanner',
  yieldResult: '/yield-result',
  liquidity: '/liquidity',
  tokenMigration: '/migrate',
  addLiquidity: '/add-liquidity',
  emergencyWithdraw: '/emergency-withdraw',
  chatBotUI: '/liq-chatbot',
};

export const samePagePaths = [paths.emergencyWithdraw, paths.tokenMigration];
