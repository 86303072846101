import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type AddLiquidityIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const AddLiquidityIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: AddLiquidityIconType) => (
  <svg width={size} height={size} fill={color} viewBox='0 0 64 80' {...props}>
    <path d='M23.718 28.383a3.485 3.485 0 0 1 3.502 3.48 1 1 0 1 0 2 0 5.488 5.488 0 0 0-4.488-5.386v-3.141a1 1 0 0 0-2 0v3.14a5.488 5.488 0 0 0 1.006 10.876 3.488 3.488 0 1 1-3.494 3.488 1 1 0 0 0-2 .006 5.488 5.488 0 0 0 4.488 5.387v3.135a1 1 0 0 0 2 0v-3.134a5.49 5.49 0 0 0-1.007-10.882 3.485 3.485 0 0 1-.007-6.969Z' />
    <path d='M45.425 35.098c-6.711.245-3.363 3.11-6.605-3.313L26.359 10.21a3.045 3.045 0 0 0-5.253-.003L8.645 31.786a17.247 17.247 0 0 0-.005 17.426c5.074 9.32 18.423 11.585 26.325 4.605a12.284 12.284 0 0 0 10.46 5.867c16.302-.674 16.307-23.91 0-24.586ZM23.732 55.924a15.423 15.423 0 0 1-13.356-23.138L22.84 11.203a1.027 1.027 0 0 1 1.785.003c1.492 3.05 13.941 22.857 14.148 25.863a12.242 12.242 0 0 0-4.746 14.906 15.24 15.24 0 0 1-10.295 3.95Zm21.693 1.76a10.304 10.304 0 0 1-10.293-10.293c.565-13.654 20.022-13.652 20.585 0a10.304 10.304 0 0 1-10.292 10.293Z' />
    <path d='M45.416 43.049a1.672 1.672 0 0 1 1.683 1.668 1 1 0 0 0 2 0 3.663 3.663 0 0 0-2.674-3.513v-1.567a1 1 0 0 0-2 0v1.565a3.662 3.662 0 0 0 1.003 7.19 1.674 1.674 0 1 1-1.677 1.673 1 1 0 0 0-2 .004 3.663 3.663 0 0 0 2.674 3.514v1.563a1 1 0 0 0 2 0v-1.562a3.665 3.665 0 0 0-1.004-7.193 1.672 1.672 0 0 1-.005-3.342ZM47.387 21.695a1.256 1.256 0 0 1-1.255-1.258 1 1 0 0 0-2 .003 3.247 3.247 0 0 0 2.258 3.081v1.206a1 1 0 1 0 2 0v-1.205a3.254 3.254 0 0 0-1.003-6.343 1.256 1.256 0 1 1 1.26-1.258 1 1 0 0 0 2 0 3.247 3.247 0 0 0-2.257-3.08v-1.21a1 1 0 1 0-2 0v1.209a3.247 3.247 0 0 0 1.003 6.34 1.258 1.258 0 0 1-.006 2.515Z' />
    <path d='M47.39 29.304c7.177.19 12.029-8.209 8.273-14.33l-5.64-9.77a3.059 3.059 0 0 0-5.267 0l-5.64 9.77c-3.756 6.12 1.095 14.523 8.274 14.33Zm-6.542-13.33 5.64-9.77a1.04 1.04 0 0 1 1.803 0l5.64 9.77a7.478 7.478 0 0 1 0 7.553c-5.274 8.504-17.811 1.266-13.083-7.553Z' />
  </svg>
);

export default AddLiquidityIcon;
