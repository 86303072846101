import {useCallback, useContext, useEffect, useState} from 'react';

import {Currency, CurrencyAmount} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {useNavigate} from 'react-router';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {paths} from 'src/constants';
import {failHandleEnum} from 'src/constants/migrator';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useLiquidityPool} from 'src/hooks/useLiquidityPool';
import {COLORS} from 'src/styles';
import {IItemType, IWhitelistToken} from 'src/types';
import styled from 'styled-components';

import {ApproveLpTokens} from './ApproveLpTokens';
import {RemoveLiquidity} from './RemoveLiquidity';
import {RemoveLiquiditySuccessful} from './SuccessfulRemoveLiquidity';

export type RemoveLiquidityModalProps = {
  isOpen: boolean;
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  tokenAddressA: string;
  tokenAddressB: string;
  slippage: IItemType;
  liquidityNumber: number;
  liquidityBN: BigNumber;
  lpCurrencyAmount: CurrencyAmount<Currency>;
  inputValue: number;
  token0Amount: number;
  token1Amount: number;
  token0MinAmount: BigNumber;
  token1MinAmount: BigNumber;
  deadline: number;
  nativeTokenInvolved: IWhitelistToken;
  onDismiss?: () => void;
};

export const RemoveLiquidityModal = ({
  isOpen = false,
  token0,
  token1,
  liquidityBN,
  liquidityNumber,
  tokenAddressA,
  tokenAddressB,
  lpCurrencyAmount,
  token0Amount,
  token1Amount,
  token0MinAmount,
  token1MinAmount,
  deadline,
  nativeTokenInvolved,
  slippage,
  inputValue,
  onDismiss,
}: RemoveLiquidityModalProps) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Remove Liquidity');
  const [failHandle, setFailHandle] = useState<failHandleEnum>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const {refreshFarmsAndLPs, refreshTokens} = useContext(AppContext);
  const {account} = useWeb3React();
  const modalContext = useModals();
  const navigate = useNavigate();

  const {isLpApproved, approveLpTokens, removeLiquidityTx} = useLiquidityPool({
    token0,
    token1,
    token0Address: token0?.address ?? tokenAddressA,
    token1Address: token1?.address ?? tokenAddressB,
    liquidity: liquidityBN,
    token0MinAmount,
    token1MinAmount,
    userAddress: account,
    deadline,
    isNative: nativeTokenInvolved ? true : false,
    lpCurrencyAmount,
  });

  useEffect(() => {
    if (isLpApproved) setStep(1);
  }, [isLpApproved, token0, token1, isOpen]);

  const onApproveLpTokens = useCallback(async () => {
    setLoading(true);
    setErrorMsg(undefined);
    if (!isLpApproved) {
      try {
        const approveTx = await approveLpTokens();
        await approveTx.wait();
        setStep(1);
      } catch (e) {
        //@ts-expect-error: error object contains reason and message
        if (e.reason !== zapKnownErrorReasons.USERREJECTION) {
          console.log({e});
          //@ts-expect-error: error object contains reason and message
          setErrorMsg(e.reason);
          setFailHandle(failHandleEnum.APPROVELPTOKENS);
        }
        setLoading(false);
        return;
      }
    } else if (isLpApproved) {
      setStep(0);
    }
    setLoading(false);
  }, [approveLpTokens, isLpApproved]);

  // remove liquidity
  const OnRemoveLiquidity = useCallback(async () => {
    setLoading(true);
    setErrorMsg(undefined);
    try {
      const removeLiquidity = await removeLiquidityTx();
      await removeLiquidity.wait();
      setTitle('Remove LP Successful');
      setStep(2);
    } catch (e) {
      //@ts-expect-error: error object contains reason and message
      if (e.reason !== zapKnownErrorReasons.USERREJECTION) {
        console.log({e});
        //@ts-expect-error: error object contains reason and message
        setErrorMsg(e.reason ?? e.message);
        setFailHandle(failHandleEnum.ADDLIQUIDITY);
      }
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [removeLiquidityTx]);

  const onHandleDismiss = () => {
    // setStep(0);
    setErrorMsg(undefined);
    return onDismiss();
  };

  const handleOpenSwapSettings = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateSwapSettingsModal', payload});
  };

  const onFail = async () => {
    switch (failHandle) {
      case failHandleEnum.APPROVELPTOKENS:
        return onApproveLpTokens();
      case failHandleEnum.REMOVELIQUIDITY:
        return OnRemoveLiquidity();
      default:
        return onHandleDismiss();
    }
  };

  const onSuccess = () => {
    onHandleDismiss();
    refreshTokens(true);
    refreshFarmsAndLPs();
    navigate(paths.liquidity);
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onHandleDismiss}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={onHandleDismiss}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <ApproveLpTokens
            token0={token0}
            token1={token1}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveLpTokens}
            errorMsg={errorMsg}
            onFail={onFail}
          />
        )}
        {step === 1 && (
          <RemoveLiquidity
            token0={token0}
            token1={token1}
            liquidity={liquidityNumber}
            token0Amount={token0Amount}
            token1Amount={token1Amount}
            slippage={slippage?.value}
            totalInputValue={inputValue}
            loading={loading}
            disabled={loading}
            onConfirm={OnRemoveLiquidity}
            errorMsg={errorMsg}
            onFail={onFail}
            onSettings={handleOpenSwapSettings}
          />
        )}
        {step === 2 && (
          <RemoveLiquiditySuccessful
            token0={token0}
            token1={token1}
            liquidity={liquidityNumber}
            token0Amount={token0Amount}
            token1Amount={token1Amount}
            totalInputValue={inputValue}
            loading={loading}
            disabled={loading}
            onConfirm={onSuccess}
            errorMsg={errorMsg}
            onFail={onFail}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
