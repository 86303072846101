import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import moment from 'moment';
import {useSearchParams} from 'react-router-dom';
import Information from 'src/assets/images/icons/information-icon.svg';
import {FarmItem, TokenItem} from 'src/components/Holdings';
import {DepositModal, DepositModalProps} from 'src/components/Modals/Deposit';
import {HarvestModal, HarvestModalProps} from 'src/components/Modals/Harvest';
import {MigrationModal, MigrationModalProps} from 'src/components/Modals/MigrationModal';
import {SecurityRatingModal} from 'src/components/Modals/SecurityRating';
import {SingleTopupModal, SingleTopupModalProps} from 'src/components/Modals/SingleTopup';
import {Spinner} from 'src/components/Spinner';
import {GuardIcon} from 'src/components/Svgs';
import {TabPanel, Tabs} from 'src/components/Tabs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {liquidusPlatformSlug} from 'src/constants/liquidity';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useToken, useUserFarm} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {
  FarmType,
  IFarm,
  IFarmingPool,
  IGauge,
  ILiquidityPool,
  IPlatform,
  IWhitelistToken,
  IYieldFarm,
  POOL_TYPE,
} from 'src/types';
import {convertFrom1970sDate} from 'src/utils/date-util';
import {getPlatformIcon} from 'src/utils/icon-util';
import {getPlatform} from 'src/utils/liquidity-utils';
import {convertKMPrice} from 'src/utils/text-util';
import {
  calculatePrice,
  formatBigNumber,
  getLpTokenPrice,
  getNextWithdrawalTime,
  getTokenUSDPrice,
  getVestingInMonth,
  parseBNumber,
} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import AuditBanner from './components/AuditBanner';
import {RemoveLiquidity} from './components/RemoveLiquidity';
import {SingleTopUp} from './components/SingleTopUp';
import {SingleWithdraw} from './components/SingleWithdraw';
import {TopUp} from './components/TopUp';
import {Withdraw} from './components/Withdraw';

export type ContentProps = {
  name?: string;
  platform?: IPlatform;
  isPool?: boolean;
  possibleFarmingPools?: (IFarmingPool | IFarm | IGauge)[];
  migrationFarmingPool?: IYieldFarm;
  yourWorth?: number;
  apy?: number;
  estimatedEarnings?: number;
  earningsPeriodText?: string;
  token0Balance?: number;
  token1Balance?: number;
  token0Detail?: IWhitelistToken;
  token1Detail?: IWhitelistToken;
  rewards?: number;
  rewardTokenDetail?: IWhitelistToken;
  nextWithdrawalTime?: string;
  hasVestingInMonth?: boolean;
  shouldShowWithdrawal?: boolean;
  isFarmReadyForWithdrawal?: boolean;
  liquidityPool?: ILiquidityPool;
  farmItem?: IYieldFarm;
  isPlatformLiquidity?: boolean;
};

const Content = ({
  name,
  platform,
  isPool,
  possibleFarmingPools,
  migrationFarmingPool,
  yourWorth,
  apy,
  estimatedEarnings,
  earningsPeriodText,
  token0Balance,
  token1Balance,
  token0Detail,
  token1Detail,
  rewards,
  rewardTokenDetail,
  nextWithdrawalTime,
  hasVestingInMonth,
  shouldShowWithdrawal,
  isFarmReadyForWithdrawal,
  liquidityPool,
  farmItem,
  isPlatformLiquidity,
}: ContentProps) => {
  const platformName = platform?.name;
  const securityRating = platform?.securityRating?.securityRating;
  const title = isPlatformLiquidity ? 'Liquidity Pool' : 'Pool Details';
  const tabLabel1 = isPlatformLiquidity ? 'Add' : 'Top-up';
  const tabLabel2 = isPlatformLiquidity ? 'Remove' : 'Withdraw';

  const {check} = useAgreementCheck();

  const modalContext = useModals();
  const [showSecurityRatingPopup, setShowSecurityRatingPopup] = useState(false);
  const [depositModalProps, setDepositModalProps] = useState<DepositModalProps>();
  const [harvestModalProps, setHarvestModalProps] = useState<HarvestModalProps>();
  const [topupModalProps, setTopupModalProps] = useState<SingleTopupModalProps>();
  const [migrationModalProps, setMigrationModalProps] = useState<MigrationModalProps>();
  const rewardUSDPrice = useMemo(() => {
    const rewardUSDPriceUnit = calculatePrice(rewardTokenDetail?.priceUSD, rewardTokenDetail?.priceDecimals);
    const _usdPrice = rewards * rewardUSDPriceUnit;
    return _usdPrice;
  }, [rewards, rewardTokenDetail]);

  const handleDeposit = useCallback(
    (farm?: IYieldFarm) => {
      setDepositModalProps({
        isOpen: true,
        lp: liquidityPool,
        farm,
      });
    },
    [liquidityPool],
  );

  const handleMigration = useCallback(
    (farm?: IYieldFarm) => {
      const diffAPY = (farm?.apy || 0) - liquidityPool?.poolApy;
      const yearlyRevenue = (yourWorth * diffAPY) / 100;
      const lpInfo = `${liquidityPool?.platform?.name} Lp - ${liquidityPool?.poolRewards?.toFixed(1)}% APY`;
      const hightestPooltvl = `$${convertKMPrice(farm?.tvlNumber || 0)} TVL`;
      const lpIconUrl =
        liquidityPool?.platform?.iconUrl && liquidityPool?.platform?.iconUrl !== ''
          ? liquidityPool?.platform?.iconUrl
          : getPlatformIcon(liquidityPool?.platform.name);
      const highIconUrl = farm.platform?.iconUrl || getPlatformIcon(farm.platform.name);

      check(() =>
        setMigrationModalProps({
          isOpen: true,
          yearlyRevenue,
          highestAPYPool: farm,
          lowestAPYPool: undefined,
          name,
          iconUrl: lpIconUrl,
          lowestPoolInfo: undefined,
          lowestPooltvl: undefined,
          hightestPooltvl,
          highIconUrl,
          isLp: true,
          lp: liquidityPool,
          lpInfo,
        }),
      );
    },
    [check, liquidityPool, name, yourWorth],
  );

  const handleDismissDepositModal = () => {
    setDepositModalProps({...depositModalProps, isOpen: false});
  };

  const handleDismissMigrationModal = () => {
    setMigrationModalProps({...migrationModalProps, isOpen: false});
  };

  const handleRewards = () => {
    check(() =>
      setHarvestModalProps({isOpen: true, farm: farmItem, priceUsedInPool: rewardUSDPrice, rewardsEarned: rewards}),
    );
  };

  const handleDismissHarvestModal = () => {
    setHarvestModalProps({...harvestModalProps, isOpen: false});
  };

  const handleDismissSingleTopupModal = () => {
    setTopupModalProps({...topupModalProps, isOpen: false});
  };

  const openYieldModal = () => {
    const payload = {
      isOpen: true,
      farm: farmItem,
      lp: liquidityPool,
      isPool: isPool,
    };
    modalContext.dispatch({type: 'updateYieldInfoModal', payload});
  };

  return (
    <Wrapper>
      <Container>
        <LeftPanel>
          <ContentlWrapper>
            <StyledFullRow>
              <BodyVariant color={COLORS.PRIMARY}>{title}</BodyVariant>
              {securityRating && (
                <SecurityRatingWrapper onClick={() => setShowSecurityRatingPopup(true)}>
                  <GuardIcon size={24} />
                  <SecuriityRatingLabel color={COLORS.PRIMARY}>{securityRating.toFixed(0)}</SecuriityRatingLabel>
                </SecurityRatingWrapper>
              )}
              {farmItem && farmItem?.platform.slug === 'liquidus' ? (
                <AuditBanner specifiedAuditorName='hacken' />
              ) : null}
            </StyledFullRow>
            <StyledFullRow marginTop={25}>
              <DynamicStyledCol>
                <BodyVariant color={COLORS.GRAY_LIGHT} size={BODY_FONT_ENUM.SMALL} mobile={BODY_FONT_ENUM.SMALL_MOBILE}>
                  TOKEN
                </BodyVariant>
                <PoolInfoWithBorder>
                  <TokenBox>
                    <TokenIcon width={24} height={24} token={token0Detail} />
                    {token1Detail && <SecondTokenIcon width={24} height={24} token={token1Detail} />}
                  </TokenBox>
                  <StyledPoolName
                    color={COLORS.PRIMARY}
                    size={BODY_FONT_ENUM.SMALL}
                    mobile={BODY_FONT_ENUM.SMALL_MOBILE}
                  >
                    {name}
                  </StyledPoolName>
                </PoolInfoWithBorder>
              </DynamicStyledCol>
              <DynamicStyledCol>
                <ApyLabelButton onClick={openYieldModal}>
                  <BodyVariant
                    color={COLORS.GRAY_LIGHT}
                    size={BODY_FONT_ENUM.SMALL}
                    mobile={BODY_FONT_ENUM.SMALL_MOBILE}
                  >
                    APY
                  </BodyVariant>
                  <StyledImage src={Information} />
                </ApyLabelButton>
                <ApyPoolInfoWithBorder>
                  <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL} mobile={BODY_FONT_ENUM.SMALL_MOBILE}>
                    {apy ? `${apy.toFixed(1)}%` : 'N/A'}
                  </BodyVariant>
                </ApyPoolInfoWithBorder>
              </DynamicStyledCol>
              <DynamicStyledCol>
                <BodyVariant color={COLORS.GRAY_LIGHT} size={BODY_FONT_ENUM.SMALL} mobile={BODY_FONT_ENUM.SMALL_MOBILE}>
                  {isPool ? 'POOL' : 'FARM'}
                </BodyVariant>
                <PoolInfoWithBorder>
                  <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL} mobile={BODY_FONT_ENUM.SMALL_MOBILE}>
                    {platformName}
                  </BodyVariant>
                </PoolInfoWithBorder>
              </DynamicStyledCol>
            </StyledFullRow>
            <StyledFullRow marginTop={25}>
              <StyledCol gap={10}>
                <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                  YOUR WORTH
                </BodyParagraph>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.BUTTON} mobile={BODY_FONT_ENUM.SMALL}>
                  ${yourWorth?.toFixed(2)}
                </BodyVariant>
              </StyledCol>
              {!isPlatformLiquidity && (
                <StyledCol gap={10}>
                  <NoWrapBodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                    {earningsPeriodText} EARNINGS
                  </NoWrapBodyParagraph>
                  <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.BUTTON} mobile={BODY_FONT_ENUM.SMALL}>
                    {estimatedEarnings && estimatedEarnings >= 0.01 ? `$${estimatedEarnings.toFixed(2)}` : 'N/A'}
                  </BodyVariant>
                </StyledCol>
              )}
            </StyledFullRow>
            <StyledCol marginTop={24}>
              {token0Detail && (
                <StyledTokenItem token={token0Detail} value={token0Balance?.toFixed(token0Detail.interfaceDecimals)} />
              )}
              {token1Detail && (
                <StyledTokenItem token={token1Detail} value={token1Balance?.toFixed(token1Detail.interfaceDecimals)} />
              )}
            </StyledCol>
            {!isPlatformLiquidity && (
              <StyledCol marginTop={20}>
                <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                  REWARDS
                </BodyParagraph>
                {rewardTokenDetail ? (
                  <RewardsTokenItem
                    token={rewardTokenDetail}
                    value={rewards ? rewards?.toFixed(rewardTokenDetail.interfaceDecimals) : 0}
                    price={rewardUSDPrice?.toFixed(2)}
                    onPress={rewardUSDPrice ? handleRewards : undefined}
                  />
                ) : (
                  <StyledCol marginTop={8}>
                    <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                      You are only earning trading fees.
                      <br />
                      The liquidity pool tokens are not deposited in a farm
                    </BodyParagraph>
                  </StyledCol>
                )}
              </StyledCol>
            )}
            {!isPlatformLiquidity && isPool && possibleFarmingPools?.length > 0 && (
              <StyledCol marginTop={16}>
                <BodyVariant color={COLORS.PRIMARY}>Possible farms</BodyVariant>
                <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                  Start farming this pool and earn more.
                </BodyParagraph>
                {possibleFarmingPools?.map((item, index) => (
                  <FarmItem key={index} data={item} type={1} onPress={() => handleDeposit(item)} />
                ))}
                {migrationFarmingPool && (
                  <FarmItem
                    data={migrationFarmingPool}
                    type={1}
                    onPress={() => handleMigration(migrationFarmingPool)}
                  />
                )}
              </StyledCol>
            )}
            {shouldShowWithdrawal && hasVestingInMonth == false && (
              <StyledCol marginTop={16}>
                <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
                  Next possible withdrawal: {nextWithdrawalTime}
                </BodyParagraph>
              </StyledCol>
            )}
          </ContentlWrapper>
        </LeftPanel>
        <RightPanel>
          <Tabs isPlatformLP={isPlatformLiquidity}>
            <TabPanel label={tabLabel1}>
              {token1Detail ? (
                <TopUp
                  token0Detail={token0Detail}
                  token1Detail={token1Detail}
                  liquidityPool={liquidityPool}
                  farmItem={farmItem}
                  isPlatformLiquidity={isPlatformLiquidity}
                />
              ) : (
                <SingleTopUp token0Detail={token0Detail} farmItem={farmItem} />
              )}
            </TabPanel>
            <TabPanel label={tabLabel2} disabled={!isFarmReadyForWithdrawal}>
              {isPlatformLiquidity ? (
                <RemoveLiquidity
                  token0Detail={token0Detail}
                  token1Detail={token1Detail}
                  liquidityPool={liquidityPool}
                  farmItem={farmItem}
                  isPool={isPool}
                  yourWorth={yourWorth}
                />
              ) : token1Detail && !isPlatformLiquidity ? (
                <Withdraw
                  token0Detail={token0Detail}
                  token1Detail={token1Detail}
                  liquidityPool={liquidityPool}
                  farmItem={farmItem}
                  isPool={isPool}
                  yourWorth={yourWorth}
                />
              ) : (
                <SingleWithdraw token0Detail={token0Detail} farmItem={farmItem} yourWorth={yourWorth} />
              )}
            </TabPanel>
          </Tabs>
        </RightPanel>
      </Container>
      <SecurityRatingModal
        isOpen={showSecurityRatingPopup}
        onDismiss={() => setShowSecurityRatingPopup(false)}
        platform={platform}
      />
      <DepositModal {...depositModalProps} onDismiss={handleDismissDepositModal} />
      <HarvestModal {...harvestModalProps} onDismiss={handleDismissHarvestModal} />
      <SingleTopupModal {...topupModalProps} onDismiss={handleDismissSingleTopupModal} />
      <MigrationModal {...migrationModalProps} onDismiss={handleDismissMigrationModal} />
    </Wrapper>
  );
};

const FarmingPoolDetail = ({
  address,
  type,
  earningPeriod,
  earningsPeriodText,
}: {
  address: string;
  type?: number;
  earningPeriod?: number;
  earningsPeriodText: string;
}) => {
  const {getTokenByAddress} = useToken();
  const {getLiqInfo} = useUserFarm();
  const {farmingPools, farms, gauges, grizzlyFarms} = useContext(AppContext);
  const data = useMemo(() => {
    const mergedFarms: IYieldFarm[] = [...farmingPools, ...farms, ...gauges, ...grizzlyFarms];
    return mergedFarms.find((item) =>
      type === FarmType.FARM_MASTERCHEF
        ? item.liquidityPool?.address?.hash === address
        : item.contractAddress?.hash === address,
    );
  }, [farmingPools, farms, gauges, grizzlyFarms, address, type]);
  const [isFarmReadyForWithdrawal, setIsFarmReadyForWithdrawl] = useState(false);
  const [nextWithdrawalTime, setNextWithdrawlTime] = useState('');
  const isFarmFull = data?.balance && data?.balance.gt(BigNumber.from(0));
  const shouldShowWithdrawal = !!nextWithdrawalTime && isFarmFull;
  const vestingInMonth = getVestingInMonth(data?.vestingTimeInDays);
  const hasNoVestingInMonth = vestingInMonth <= 0 && vestingInMonth !== undefined;
  const liquidityPool = data?.liquidityPool;
  const name = `${liquidityPool?.token0Symbol} - ${liquidityPool?.token1Symbol}`;
  const apy = data?.apy;
  const poolBalance = formatBigNumber(data?.balance, liquidityPool?.decimals);
  const yourWorth = getTokenUSDPrice(poolBalance, liquidityPool?.lpTokenPriceUSD);
  const totalSupply = parseBNumber(liquidityPool?.totalSupply, liquidityPool?.decimals) || 1;
  const userRatioPool = poolBalance / totalSupply;
  const token0Detail = getTokenByAddress(liquidityPool?.token0Hash);
  const token1Detail = getTokenByAddress(liquidityPool?.token1Hash);
  const rewardTokenDetail = getTokenByAddress(data?.rewardTokenChainSpecifics?.address?.hash);
  const rewards = data?.pendingReward;
  const token0Balance = userRatioPool * parseBNumber(liquidityPool?.reserve0, token0Detail?.decimals);
  const token1Balance = userRatioPool * parseBNumber(liquidityPool?.reserve1, token1Detail?.decimals);
  const isLpFarm = data?.type === FarmType.FARM_LP;

  const calcEstimatedEarnings = () => {
    const earnings = ((apy || 0) * yourWorth) / earningPeriod / 100;
    return earnings;
  };

  const fetchPoolDetails = useCallback(async () => {
    if (data) {
      if (isLpFarm) {
        const _liqInfo = await getLiqInfo(data);
        if (_liqInfo) {
          const _userInfo = _liqInfo[0];
          if (_userInfo) {
            const _lastDepositAt = BigNumber.from(_userInfo[2]).toNumber();
            const _nextWithdrawlDateTime = getNextWithdrawalTime(vestingInMonth, _lastDepositAt);
            setNextWithdrawlTime(_nextWithdrawlDateTime);

            const dateInMilliseconds = new Date().getTime();
            const dateString = moment(convertFrom1970sDate(dateInMilliseconds, 'YYYY-MM-DD HH:mm'));

            setIsFarmReadyForWithdrawl(dateString.isAfter(moment(_nextWithdrawlDateTime)) && isFarmFull);
          }
        }
      } else {
        setIsFarmReadyForWithdrawl(isFarmFull);
        setNextWithdrawlTime('');
      }
    }
  }, [data, isLpFarm, getLiqInfo, vestingInMonth, isFarmFull]);

  useEffect(() => {
    fetchPoolDetails();
  }, [fetchPoolDetails]);

  if (!liquidityPool) return null;
  return (
    <Content
      name={name}
      platform={data?.platform}
      isPool={liquidityPool?.balance?.gt(0)}
      yourWorth={yourWorth}
      apy={apy}
      estimatedEarnings={calcEstimatedEarnings()}
      earningsPeriodText={earningsPeriodText}
      token0Balance={token0Balance}
      token1Balance={token1Balance}
      token0Detail={token0Detail}
      token1Detail={token1Detail}
      rewards={rewards}
      rewardTokenDetail={rewardTokenDetail}
      nextWithdrawalTime={nextWithdrawalTime}
      hasVestingInMonth={hasNoVestingInMonth}
      shouldShowWithdrawal={shouldShowWithdrawal}
      isFarmReadyForWithdrawal={isFarmReadyForWithdrawal}
      liquidityPool={liquidityPool}
      farmItem={data}
    />
  );
};

const LiquidityPoolDetail = ({
  address,
  earningPeriod,
  earningsPeriodText,
}: {
  address: string;
  earningPeriod?: number;
  earningsPeriodText: string;
}) => {
  const {chainId} = useWeb3React();
  const {getTokenByAddress} = useToken();
  const {liquidityPools, farmingPools, farms, gauges} = useContext(AppContext);

  const possibleFarmingPools = useMemo(() => {
    const allFarms = [...(farmingPools ?? []), ...(farms ?? []), ...(gauges ?? [])];
    return allFarms.filter((farm) => farm?.liquidityPool?.address?.hash === address && (farm?.apy || 0) > 0.001);
  }, [farmingPools, farms, gauges, address]);

  const data = useMemo(() => {
    return liquidityPools.find((item) => item.address.hash === address);
  }, [liquidityPools, address]);
  const name = `${data?.token0Symbol} - ${data?.token1Symbol}`;
  const poolBalance = formatBigNumber(data?.balance, data?.decimals);
  const apy = data?.poolApy;
  const yourWorth = getTokenUSDPrice(poolBalance, data?.lpTokenPriceUSD);
  const totalSupply = parseBNumber(data?.totalSupply, data?.decimals) || 1;
  const userRatioPool = poolBalance / totalSupply;
  const token0Detail = getTokenByAddress(data?.token0Hash);
  const token1Detail = getTokenByAddress(data?.token1Hash);
  const token0Balance = userRatioPool * parseBNumber(data?.reserve0, token0Detail?.decimals);
  const token1Balance = userRatioPool * parseBNumber(data?.reserve1, token1Detail?.decimals);

  const migrationFarmingPool = useMemo(() => {
    const _lpFarms = farms?.filter((item) => item.contractAddress?.chainId === chainId && item.liquidityPool);
    const v2FarmingPools = farmingPools.filter((item: IFarmingPool) => {
      if (item?.masterChefAddress?.chainId === chainId && item.version !== 1 && item?.platform?.name !== 'MM Finance') {
        const {...newItem} = item;
        return newItem;
      }
      return null;
    });

    const baseFarm = possibleFarmingPools[0];
    const mergedFarms: IYieldFarm[] = [...v2FarmingPools, ..._lpFarms];
    const otherPossibleFarms = mergedFarms.filter(
      (farm) =>
        farm?.liquidityPool?.token0Hash === baseFarm?.liquidityPool?.token0Hash &&
        farm?.liquidityPool?.token1Hash === baseFarm?.liquidityPool?.token1Hash &&
        (farm?.apy || 0) > baseFarm.apy,
    );

    if (otherPossibleFarms.length > 0) {
      const maxAPYFarm = otherPossibleFarms.reduce((prev, current) => {
        return prev && prev.apy > current.apy ? prev : current;
      });
      const diffAPY = maxAPYFarm?.apy - baseFarm?.apy;
      const yearlyRevenue = (yourWorth * diffAPY) / 100;
      if (yearlyRevenue >= 0.1) return maxAPYFarm;
      else return undefined;
    }
  }, [farmingPools, farms, chainId, possibleFarmingPools, yourWorth]);

  const calcEstimatedEarnings = () => {
    const earnings = ((apy || 0) * yourWorth) / earningPeriod / 100;
    return earnings;
  };

  if (!data) return null;
  return (
    <Content
      name={name}
      platform={data?.platform}
      isPool={poolBalance > 0}
      possibleFarmingPools={possibleFarmingPools}
      migrationFarmingPool={migrationFarmingPool}
      apy={apy}
      yourWorth={yourWorth}
      estimatedEarnings={calcEstimatedEarnings()}
      earningsPeriodText={earningsPeriodText}
      token0Balance={token0Balance}
      token1Balance={token1Balance}
      token0Detail={token0Detail}
      token1Detail={token1Detail}
      isFarmReadyForWithdrawal={true}
      liquidityPool={data}
    />
  );
};

const PlatformLiquidityPoolDetail = ({
  address,
  earningPeriod,
  earningsPeriodText,
}: {
  address: string;
  earningPeriod?: number;
  earningsPeriodText: string;
}) => {
  const {importLiquidityData} = useAppSelector((state) => state?.liquidity);
  const {token0Address, token1Address, liquidityData} = importLiquidityData;
  const {reserve0, reserve1, totalSupply, decimals} = liquidityData;
  const {getTokenByAddress} = useToken();
  const {liquidityPools, farmingPools} = useContext(AppContext);
  const platform = getPlatform(liquidityPools, farmingPools, liquidusPlatformSlug);

  const initialLiquidityPool = !address && {
    ...liquidityData,
    platform,
    reserve0: formatBigNumber(reserve0, decimals).toString(),
    reserve1: formatBigNumber(reserve1, decimals).toString(),
    totalSupply: formatBigNumber(totalSupply, decimals).toString(),
    token0Hash: token0Address,
    token1Hash: token1Address,
  };

  let data = useMemo(() => {
    return address
      ? liquidityPools.find((item) => item.address.hash === address)
      : (initialLiquidityPool as ILiquidityPool);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liquidityPools, address]);

  const token0Detail = getTokenByAddress(data?.token0Hash);
  const token1Detail = getTokenByAddress(data?.token1Hash);

  const name = `${token0Detail?.symbol} - ${token1Detail?.symbol}`;

  const poolBalance = formatBigNumber(data?.balance, data?.decimals);
  const ts = parseBNumber(data?.totalSupply, data?.decimals) || 1;
  const userRatioPool = poolBalance / ts;

  const token0Balance = userRatioPool * parseBNumber(data?.reserve0, token0Detail?.decimals);
  const token1Balance = userRatioPool * parseBNumber(data?.reserve1, token1Detail?.decimals);

  const lpTokenPriceUSD = !address
    ? getLpTokenPrice(token0Detail, token1Detail, reserve0, reserve1, totalSupply)
    : data.lpTokenPriceUSD;

  const yourWorth = getTokenUSDPrice(poolBalance, lpTokenPriceUSD);
  const apy = data?.poolApy;

  if (!address) {
    data = {
      ...initialLiquidityPool,
      lpTokenPriceUSD,
    } as ILiquidityPool;
  }

  const calcEstimatedEarnings = () => {
    const earnings = ((apy || 0) * yourWorth) / earningPeriod / 100;
    return earnings;
  };

  if (!data) return null;
  return (
    <Content
      name={name}
      platform={data?.platform}
      isPool={poolBalance > 0}
      apy={apy}
      yourWorth={yourWorth}
      estimatedEarnings={calcEstimatedEarnings()}
      earningsPeriodText={earningsPeriodText}
      token0Balance={token0Balance}
      token1Balance={token1Balance}
      token0Detail={token0Detail}
      token1Detail={token1Detail}
      isFarmReadyForWithdrawal={true}
      liquidityPool={data}
      isPlatformLiquidity={true}
    />
  );
};

const TokenDepositlDetail = ({
  address,
  earningPeriod,
  earningsPeriodText,
}: {
  address: string;
  earningPeriod?: number;
  earningsPeriodText: string;
}) => {
  const {getTokenByAddress} = useToken();
  const {getLiqInfo} = useUserFarm();
  const {farms} = useContext(AppContext);
  const data = useMemo(() => {
    return farms.find((item) => item.contractAddress.hash === address);
  }, [farms, address]);
  const [isFarmReadyForWithdrawal, setIsFarmReadyForWithdrawl] = useState(false);
  const [nextWithdrawalTime, setNextWithdrawlTime] = useState('');
  const isFarmFull = data?.balance && data?.balance.gt(BigNumber.from(0));
  const shouldShowWithdrawal = !!nextWithdrawalTime && isFarmFull;
  const symbol = data?.rewardTokenChainSpecifics?.symbol;
  const stakedTokenAddress = data?.stakedTokenChainSpecifics.address.hash;
  const vestingInMonth = getVestingInMonth(data?.vestingTimeInDays);
  const hasNoVestingInMonth = vestingInMonth <= 0 && vestingInMonth !== undefined;
  const name = `${symbol} - ${vestingInMonth} Month`;
  const apy = data?.apy;
  const singleToken = getTokenByAddress(stakedTokenAddress);
  const poolBalance = formatBigNumber(data?.balance, singleToken?.decimals);
  const tokenPriceUSDUnit = calculatePrice(singleToken?.priceUSD, singleToken?.priceDecimals);
  const yourWorth = poolBalance * tokenPriceUSDUnit;
  const rewards = data?.pendingReward;

  const calcEstimatedEarnings = () => {
    const earnings = ((apy || 0) * yourWorth) / earningPeriod / 100;
    return earnings;
  };

  const fetchPoolDetails = useCallback(async () => {
    if (data) {
      const _liqInfo = await getLiqInfo(data);
      if (_liqInfo) {
        const _userInfo = _liqInfo[0];
        if (_userInfo) {
          const _lastDepositAt = BigNumber.from(_userInfo[2]).toNumber();
          const _nextWithdrawlDateTime = getNextWithdrawalTime(vestingInMonth, _lastDepositAt);
          setNextWithdrawlTime(_nextWithdrawlDateTime);

          const dateInMilliseconds = new Date().getTime();
          const dateString = moment(convertFrom1970sDate(dateInMilliseconds, 'YYYY-MM-DD HH:mm'));

          setIsFarmReadyForWithdrawl(dateString.isAfter(moment(_nextWithdrawlDateTime)) && isFarmFull);
        }
      }
    }
  }, [data, getLiqInfo, vestingInMonth, isFarmFull]);

  useEffect(() => {
    fetchPoolDetails();
  }, [fetchPoolDetails]);

  if (!data) return null;
  return (
    <Content
      name={name}
      platform={data?.platform}
      isPool={false}
      yourWorth={yourWorth}
      apy={apy}
      estimatedEarnings={calcEstimatedEarnings()}
      earningsPeriodText={earningsPeriodText}
      token0Balance={poolBalance}
      token0Detail={singleToken}
      rewards={rewards}
      rewardTokenDetail={singleToken}
      nextWithdrawalTime={nextWithdrawalTime}
      hasVestingInMonth={hasNoVestingInMonth}
      shouldShowWithdrawal={shouldShowWithdrawal}
      isFarmReadyForWithdrawal={isFarmReadyForWithdrawal}
      liquidityPool={data.liquidityPool}
      farmItem={data}
    />
  );
};

export const PoolDetail = () => {
  const [searchParms] = useSearchParams();
  const {farmLoading} = useContext(AppContext);
  const type = searchParms.get('type');
  const address = searchParms.get('address');
  const farmType = searchParms.get('farm-type');
  const platform = searchParms.get('platform');
  const isImport = searchParms.get('import');

  const EarningPeriods = {
    Yearly: 1,
    Monthly: 12,
    Weekly: 52,
    Daily: 365,
  };

  const EarningsPeriodtext = (earningPeriod: number) => {
    if (earningPeriod == 1) {
      return 'YEARLY';
    }
    if (earningPeriod == 12) {
      return 'MONTHLY';
    }
    if (earningPeriod == 52) {
      return 'WEEKLY';
    }
    if (earningPeriod == 365) {
      return 'DAILY';
    }
  };

  // insert the earning peroid you would like to display
  const earningPeriod = EarningPeriods.Monthly;
  const importedPool = isImport === 'true';

  if (!type || !address) return null;

  return (
    <StyledContainer>
      {type === POOL_TYPE.FARMING_POOL ? (
        <FarmingPoolDetail
          address={address}
          type={Number(farmType)}
          earningPeriod={earningPeriod}
          earningsPeriodText={EarningsPeriodtext(earningPeriod)}
        />
      ) : (type === POOL_TYPE.LIQUIDITY_POOL && platform === 'Liquidus') || importedPool ? (
        <PlatformLiquidityPoolDetail
          address={!importedPool && address}
          earningPeriod={!importedPool && earningPeriod}
          earningsPeriodText={!importedPool && EarningsPeriodtext(earningPeriod)}
        />
      ) : type === POOL_TYPE.LIQUIDITY_POOL ? (
        <LiquidityPoolDetail
          address={address}
          earningPeriod={earningPeriod}
          earningsPeriodText={EarningsPeriodtext(earningPeriod)}
        />
      ) : (
        <TokenDepositlDetail
          address={address}
          earningPeriod={earningPeriod}
          earningsPeriodText={EarningsPeriodtext(earningPeriod)}
        />
      )}
      {farmLoading && (
        <StyledSpinner>
          <Spinner size={32} color={COLORS.SECONDARY} />
        </StyledSpinner>
      )}
    </StyledContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    padding: 20px 12px;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const LeftPanel = styled.div`
  width: 100%;
  min-width: 365px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 80%;
  }
`;

const RightPanel = styled.div`
  width: 90%;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 80%;
    min-width: 365px;
  }
`;

const ContentlWrapper = styled.div`
  padding: 31px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 12px;
  width: 100%;
`;

const StyledFullRow = styled.div<{marginTop?: number}>`
  display: flex;
  gap: 10px;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  justify-content: space-between;
  align-items: center;
`;

const PoolInfoWithBorder = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 12px;
  background: rgba(23, 231, 214, 0.098894);
  border: 1px solid ${COLORS.SECONDARY};
  border-radius: 9px;
  gap: 4px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    justify-content: center;
    padding: 0 6px;
  }
`;

const ApyPoolInfoWithBorder = styled(PoolInfoWithBorder)`
  margin-bottom: 2px;
`;

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const NoWrapBodyParagraph = styled(BodyParagraph)`
  white-space: nowrap;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number; flex?: number}>`
  flex: ${(props) => props.flex ?? 'none'};
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const DynamicStyledCol = styled.div`
  flex: 1 1 auto;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  position: relative;
  display: block;
  height: 100%;
`;

const StyledSpinner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const SecurityRatingWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SecuriityRatingLabel = styled(BodyParagraph)`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  font-size: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;

const RewardsTokenItem = styled(TokenItem)`
  margin-top: 12px;
  cursor: pointer;
`;

const ApyLabelButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 14px;
  height: 14px;
`;

const StyledPoolName = styled(BodyVariant)`
  @media (min-width: ${DEVICE_ENUM.md}) {
    line-height: 18px;
  }

  @media (min-width: 1025px) {
    line-height: 22px;
  }
`;
