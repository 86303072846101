import React from 'react';

import {DEVICE_ENUM} from 'src/styles';
import {PreviousChatsProps} from 'src/types/ChatBot/chatBotProps';
import styled from 'styled-components';

import RemoveIcon from '../../assets/images/tokens/ic-remove.png';

const PreviousChats: React.FC<PreviousChatsProps> = ({conversations, onSelect, onDelete}) => {
  return (
    <section>
      <Title>Previous Chats</Title>
      <StyledConversationContainer>
        {conversations?.map((conversation, index) => (
          <ConversatoinItem key={index}>
            <ChatButton onClick={() => onSelect(index)}>
              {conversation.messages[0]?.question || 'No question available'}
            </ChatButton>
            <IconContainer onClick={() => onDelete(index)}>
              <Image src={RemoveIcon} />
            </IconContainer>
          </ConversatoinItem>
        ))}
      </StyledConversationContainer>
    </section>
  );
};

export default PreviousChats;

const StyledConversationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h2`
  font-family: 'Montserrat', sans-serif;
  color: #112455;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  letter-spacing: -0.04em;
  text-align: left;
  margin: 0px 0px 10px 5px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    font-size: 16px;
  }
`;

const ConversatoinItem = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
`;

const ChatButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.04em;
  color: #112455;
  background: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d1d1d1;
    color: #000000;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
`;

const Image = styled.img`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
`;
