import {useContext, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import WarningIcon from 'src/assets/images/warning.png';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {ConnectWalletButton} from 'src/components/ConnectWalletButton';
import {TokenItem} from 'src/components/Holdings';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {useEmergencyWithdraw} from 'src/hooks/useEmergencyWithdraw';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {emergencyContentType} from 'src/types/zapTypes';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

export const EmergencyWithdraw = () => {
  const {account, chainId} = useWeb3React();
  const {check} = useAgreementCheck();
  const {refreshFarmsAndLPs, refreshTokens, tokens} = useContext(AppContext);

  const [contractAddress, setContractAddress] = useState('');
  const {getEmergencyWithdrawContractData, emergencyWithdrawDisplayContent, onEmergencyWithdraw} =
    useEmergencyWithdraw();

  const [derivedData, setDerivedData] = useState<emergencyContentType>(undefined);
  const [loading, setLoading] = useState(false);
  const [withdrawError, setWithdrawError] = useState(undefined);
  const [btnTitle, setBtnTitle] = useState('Emergency Withdraw');

  const disabled =
    (!derivedData && contractAddress !== '') || loading || contractAddress === '' || !derivedData?.isWithdrawPossible;

  const inValidAddress = !derivedData && contractAddress !== '' && !loading;

  const vestingError = !inValidAddress && derivedData?.isVestingComplete === false;

  const handleChange = (value: string) => {
    setWithdrawError(undefined);
    const trimmedVal = value.trim();
    setContractAddress(trimmedVal);
  };

  const handleFetch = async () => {
    try {
      if (ethers.utils.isAddress(contractAddress) && contractAddress !== '') {
        await getEmergencyWithdrawContractData(contractAddress).then(async (res) => {
          setLoading(false);
          if (!res) {
            setDerivedData(undefined);
            return;
          }
          const displayContent = emergencyWithdrawDisplayContent(res);
          if (displayContent?.token0) setDerivedData(displayContent);
        });
      } else {
        setLoading(false);
        setDerivedData(undefined);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      setBtnTitle('Emergency Withdraw');
      setWithdrawError(undefined);
      const result = await onEmergencyWithdraw(contractAddress, derivedData?.token1 && account);
      await result.wait();
      refreshFarmsAndLPs();
      refreshTokens(true);
      handleFetch();
    } catch (error: unknown) {
      console.error({error});
      //@ts-expect-error: reason is present on error object
      if (error?.reason !== zapKnownErrorReasons.USERREJECTION) {
        //@ts-expect-error: reason is present on error object
        setWithdrawError(error?.reason ?? 'Failed to withdraw tokens');
        setBtnTitle('Retry');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, chainId, tokens]);

  return (
    <Wrapper>
      <Container>
        {account ? (
          <>
            <StyledSection>
              <StyledFullRow>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
                  Emergency Withdrawal Tool
                </BodyVariant>
              </StyledFullRow>
              <Description fontType={PARAGRAPH_FONT_ENUM.SMALL}>
                Use this tool only if it is not possible to withdraw regularly.
                <br />
                <br />
                By using this tool, you will receive back your deposited LP tokens, but not any pending rewards.
                <br />
                <br />
                Supported farms: LP token farms of the following projects: Liquidus, Onino, Betero, Cadaico.
                <br />
                <br />
                Vesting time must be completed.
              </Description>
              <StyledFullRow>
                <BodyVariant size={BODY_FONT_ENUM.MEDIUM}>Farm Contract</BodyVariant>
              </StyledFullRow>
              <InputBox>
                <Input
                  placeholder='Farm Contract'
                  value={contractAddress}
                  disabled={!account}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </InputBox>
              {inValidAddress && (
                <StyledWarningBox pos='flex-start'>
                  <StyledWarningIcon src={WarningIcon} />
                  <BodyParagraph color={COLORS.WARNING}>Invalid Farm</BodyParagraph>
                </StyledWarningBox>
              )}
              {vestingError && (
                <StyledWarningBox pos='flex-start'>
                  <StyledWarningIcon src={WarningIcon} />
                  <BodyParagraph color={COLORS.WARNING}>Vesting time is not reached</BodyParagraph>
                </StyledWarningBox>
              )}
              {derivedData && (
                <>
                  <StyledFarmDataWrapper>
                    <StyledFarmContentRow>
                      <BodyVariant size={BODY_FONT_ENUM.MEDIUM}>Farm</BodyVariant>
                    </StyledFarmContentRow>
                    <PoolInfoWithBorder>
                      <TokenBox>
                        <TokenIcon width={24} height={24} token={derivedData?.token0} />
                        {derivedData?.token1 && <SecondTokenIcon width={24} height={24} token={derivedData?.token1} />}
                      </TokenBox>
                      <StyledPoolName
                        color={COLORS.PRIMARY}
                        size={BODY_FONT_ENUM.SMALL}
                        mobile={BODY_FONT_ENUM.SMALL_MOBILE}
                      >
                        {derivedData?.name}
                      </StyledPoolName>
                    </PoolInfoWithBorder>
                  </StyledFarmDataWrapper>
                  <StyledFarmDataWrapper>
                    <StyledFarmContentRow>
                      <BodyVariant size={BODY_FONT_ENUM.MEDIUM}>Your Deposit</BodyVariant>
                    </StyledFarmContentRow>
                    <StyledCol>
                      <TokenItem token={derivedData?.token0} value={derivedData?.token0Balance} />
                      {derivedData?.token1 && (
                        <TokenItem token={derivedData?.token1} value={derivedData?.token1Balance} />
                      )}
                    </StyledCol>
                    <StyledConvertion>
                      <BodyParagraph color={COLORS.GRAY_LIGHT}>= {derivedData?.poolBalance} Tokens</BodyParagraph>
                    </StyledConvertion>
                  </StyledFarmDataWrapper>
                </>
              )}
            </StyledSection>
            <StyledSection>
              <StyledButton
                width='100%'
                color={COLORS.PRIMARY}
                size={BUTTON_SIZE_ENUM.DEFAULT}
                title={btnTitle}
                disabled={disabled}
                isLoading={loading}
                onClick={() => check(() => handleWithdraw())}
              />
            </StyledSection>
            {withdrawError && (
              <StyledWarningBox pos='center'>
                <StyledWarningIcon src={WarningIcon} />
                <BodyParagraph color={COLORS.WARNING}>{withdrawError}</BodyParagraph>
              </StyledWarningBox>
            )}
          </>
        ) : (
          <ConnectWalletButton />
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;

  @media (max-width: ${DEVICE_ENUM.LAPTOP}px) {
    padding: 0 10px;
  }
`;

const Container = styled.div`
  width: 712px;
  min-height: calc(100vh - 81px);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));
  padding: 80px 0;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 24px 16px;
  }
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenIconWrapper = styled.div<{size?: 'default' | 'small'}>`
  display: flex;
  margin-right: ${(props) => (props.size == 'default' ? 24 : 12)}px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFarmContentRow = styled(StyledFullRow)`
  margin: 0 0 10px;
`;

const Description = styled.p<{fontType: PARAGRAPH_FONT_ENUM}>`
  font-size: ${(props) => props.fontType};
  text-align: left;
  color: ${COLORS.PRIMARY};
  margin-bottom: 20px;
`;

const StyledFarmDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 15px 0;
`;

const StyledButton = styled(Button)<{width: string; background?: boolean; radius?: boolean}>`
  background: ${(props) => props.background && 'transparent'};
  border-radius: ${(props) => props.radius && '6px'};
  margin-top: 10px;
  width: ${(props) => props.width};
`;

const PoolInfoWithBorder = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  width: fit-content;
  padding: 0 12px;
  background: rgba(23, 231, 214, 0.098894);
  border: 1px solid ${COLORS.SECONDARY};
  border-radius: 9px;
  gap: 4px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    justify-content: center;
    padding: 0 6px;
  }
`;

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const StyledPoolName = styled(BodyVariant)`
  @media (min-width: ${DEVICE_ENUM.md}) {
    line-height: 12px;
  }

  @media (min-width: 1025px) {
    line-height: 22px;
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 20px 30px;
  gap: 10px;
  width: 100%;
  background: #9aa6cf1a;
`;

const StyledConvertion = styled(TokenIconWrapper)`
  padding: 10px 20px;
  background: transparent;
`;

const StyledWarningBox = styled.div<{pos: string}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props?.pos};
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const InputBox = styled.div`
  padding: 12px 0;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
`;
