import React from 'react';

import {DEVICE_ENUM} from 'src/styles';
import {PredefinedQuestionsProps} from 'src/types/ChatBot/chatBotProps';
import styled from 'styled-components';

const PredefinedQuestions: React.FC<PredefinedQuestionsProps> = ({questions, onSelect}) => {
  return (
    <QuestionsContainer>
      <Title>Ask Liquidus AI</Title>
      <div>
        {questions.map((question, index) => (
          <QuestionButton key={index} onClick={() => onSelect(question)}>
            {question}
          </QuestionButton>
        ))}
      </div>
    </QuestionsContainer>
  );
};

export default PredefinedQuestions;

const QuestionsContainer = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-family: 'Montserrat', sans-serif;
  color: #112455;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  letter-spacing: -0.04em;
  text-align: left;
  margin: 0px 0px 10px 5px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    font-size: 16px;
  }
`;

const QuestionButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.04em;
  color: #112455;
  background: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d1d1d1;
    color: #000000;
  }
`;
