import {DialogContent, DialogOverlay} from '@reach/dialog';
import {createPortal} from 'react-dom';
import {DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

type ModalProps = {
  isOpen: boolean;
  isSignature?: boolean;
  onDismiss?: () => void;
  children: React.ReactElement;
};

export const Modal = ({isOpen, isSignature, onDismiss, children, ...props}: ModalProps) => {
  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={onDismiss} {...props}>
      <StyledDialog signaturemodal={isSignature && 'true'}>{children}</StyledDialog>
    </StyledDialogOverlay>
  );
};

export const StandardModal = ({isOpen, children}: ModalProps) => {
  const modal = isOpen && <Wrapper>{children}</Wrapper>;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const body = document.querySelector('body')!; // <body> always exists.
  return createPortal(modal, body);
};

const StyledDialogOverlay = styled(DialogOverlay)`
  &[data-reach-dialog-overlay] {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
`;

const StyledDialog = styled(DialogContent)<{signaturemodal?: string}>`
  overflow-y: auto;

  &[data-reach-dialog-content] {
    display: flex;
    max-width: ${(props) => (props.signaturemodal ? '712px' : '420px')};
    max-height: 80vh;
    padding: 0px;
    width: ${(props) => (props.signaturemodal ? '70vw' : '50vw')};
    overflow-x: hidden;
    border-radius: 20px;

    @media (max-width: 1200px) {
      max-width: 75vw;
    }

    @media (max-width: ${DEVICE_ENUM.TABLET}) {
      width: 90vw;
      max-height: 100vh;
    }

    @media (max-width: ${DEVICE_ENUM.MOBILE}) {
      max-height: 80%;
      border-radius: ${(props) => (props.signaturemodal ? '12px' : '20px')};
      max-width: ${(props) => (props.signaturemodal ? '100vw' : '75vw')};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
`;
