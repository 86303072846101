import {createSlice} from '@reduxjs/toolkit';
import {importLiquidityType} from 'src/types/liquidity';

export interface LiquidityState {
  importLiquidityData: importLiquidityType;
}

const initialState: LiquidityState = {
  importLiquidityData: {
    token0Address: undefined,
    token1Address: undefined,
    liquidityData: {
      address: {hash: undefined},
      balance: undefined,
      decimals: undefined,
      reserve0: undefined,
      reserve1: undefined,
      totalSupply: undefined,
    },
  },
};

export const liquiditySlice = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    setImportLiquidityData(state, {payload: {importContent}}) {
      return {
        ...state,
        importLiquidityData: importContent,
      };
    },
  },
});
