import React, {useEffect} from 'react';

import {Button} from 'src/components/Buttons';
import {useModals} from 'src/contexts/modals';
import {useUserSignature} from 'src/hooks/user-signature';
import {useTandCAgreement} from 'src/hooks/user-signature/useTandCAgreement';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {CloseIcon} from '../../Svgs';
import {Modal} from '../Modal';

type UserSignatureProps = {
  isOpen: boolean;
  name?: string;
  additionalConditions?: string[];
};

export const UserSignature = ({isOpen, name, additionalConditions}: UserSignatureProps) => {
  const {requestSignature, sendAgreement} = useTandCAgreement();
  const {closeUserSignature} = useUserSignature();
  const {state} = useModals();

  const terms = [
    `I am not a resident, citizen, or incorporated entity in any Prohibited Localities as defined in the Terms of Use, nor do I have a registered office there.`,
    `I will not access this site or use ${name} while located within any Prohibited Localities, as defined in the Terms of Use.`,
    `I am not using, and will not use, a VPN to mask my physical location if I am in a restricted territory.`,
    `I am lawfully permitted to access this site and use ${name} under the laws of my jurisdiction.`,
    `I understand the risks associated with entering into using ${name} protocols.`,
  ];

  const userSignature = async () => {
    const msg = `I have read and accepted the ‘Terms of Use’, ‘Privacy Policy’, as of signing this confirmation. I specifically acknowledge the following:
    \n\n- ${terms.join('\n\n- ')} `;

    const signature = await requestSignature(msg);
    if (signature) {
      const result = await sendAgreement(signature, msg);
      if (result) {
        state.userSignature.openNextModal && state.userSignature.openNextModal();
        closeUserSignature(); // Close dialog in case of success
        console.log('T-and-C agreement created:', result);
      } else {
        console.error('Error sending agreement!');
        // TODO: Handle error in UI
      }
    }
  };

  const listItems = [...terms, ...(additionalConditions ?? [])];

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.removeProperty('overflow');
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onDismiss={closeUserSignature} isSignature={true}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Terms & Conditions aggreement</StyledTitle>
            <IconButton onClick={closeUserSignature}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <StyledColumn>
          <BodyTextContainer>{`By using ${name}, I agree to the ‘Terms of Use’, ‘Privacy Policy’`}</BodyTextContainer>
          <BodyTextContainer>
            Please read these documents carfully and accept them by clicking the Sign button below.
          </BodyTextContainer>
          <BodyTextContainer>These documents include, but aren&apos;t limited to, the following:</BodyTextContainer>
        </StyledColumn>
        <Body>
          <ListContainer overflow={listItems?.length > 10 ? true : undefined}>
            {listItems.map((item, index) => (
              <Description key={index}>{item}</Description>
            ))}
          </ListContainer>
          <ButtonWrapper>
            <Button color={COLORS.PRIMARY} title='Sign and Proceed' onClick={userSignature} />
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div<{font?: string}>`
  font-family: ${(props) => (props.font ? props.font : 'inherit')}
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: ${COLORS.PRIMARY};

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    font-size: 16px;
  }
`;

const Body = styled.div`
  padding: 0 24px 24px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    padding: 0;
  }
`;

const BodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${COLORS.PRIMARY};
`;

const ListContainer = styled.ul<{overflow?: boolean}>`
  display: flex;
  height: ${(props) => (props.overflow ? '260px' : 'fit-content')};
  overflow-y: ${(props) => (props.overflow ? 'scroll' : 'hidden')};
  padding-top: ${(props) => (props.overflow ? 3 : 0)}rem;
  margin: 2rem 1rem;
  flex-direction: column;
  justify-content: center;
  color: ${COLORS.PRIMARY};
`;

const Description = styled.li`
  font-size: 12px;
  margin: 0;
  margin-bottom: 10px;
  color: ${COLORS.GRAY_LIGHT};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    padding: 8px;
  }
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledColumn = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  padding: 12px 24px 0px;
  width: 100%;
  gap: 16px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    font-size: 13px;
  }
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
