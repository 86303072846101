import React, {useState} from 'react';

import {toast} from 'react-toastify';
import sendButton from 'src/assets/images/sendButton.png';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {ChatInputProps} from 'src/types/ChatBot/chatBotProps';
import styled from 'styled-components';

import ChatBotIcon from '../Svgs/ChatbotIcon';

const ChatInput: React.FC<ChatInputProps> = ({onSend}) => {
  const [message, setMessage] = useState<string>(undefined);

  const availInputText = message && message !== '';

  const handleSend = () => {
    try {
      if (availInputText) {
        onSend(message.trim());
        setMessage('');
      }
    } catch (e: unknown) {
      console.log({e});
      //@ts-expect-error: Error object contains either a reason or message
      toast.error(e?.reason || e?.message || 'An unknown error occurred');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && availInputText) {
      handleSend();
    }
  };

  const handleMsg = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedMsg = event.target.value;
    setMessage(trimmedMsg);
  };

  return (
    <InputContainer className='InputContainer'>
      <ChatBotIconContainer>
        <ChatBotIcon width='24' height='24' />
      </ChatBotIconContainer>
      <Input
        type='text'
        value={message}
        onChange={handleMsg}
        placeholder='Type your message here...'
        onKeyDown={handleKeyPress}
      />
      <SendButton onClick={handleSend} isText={availInputText}>
        <img src={sendButton} alt='Send' />
      </SendButton>
    </InputContainer>
  );
};

export default ChatInput;

const ChatBotIconContainer = styled.div`
  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    display: none;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #e5e8ef;
  border-radius: 12px;
  background-color: #e5e8ef;
  z-index: 1;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  color: #333;
  margin-left: 10px;

  &::placeholder {
    color: #ccc;
  }

  @media (max-width: 375px) {
    width: 50%;
  }
`;

const SendButton = styled.button<{isText?: boolean}>`
  background-color: ${(props) => (props.isText ? 'white' : COLORS.GRAY_TEXT)};
  border-radius: 10px;
  border none;
  cursor: ${(props) => (props.isText ? 'pointer' : 'default')};;
  padding: 8px;
  width: 60px;

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 30px;
    padding: 5px;
    border-radius: 5px;

    img {
      width: 10px;
      height: 10px;
    }
  }
`;
